$primary: #00B6CF;
//$secondary: #5E4F4F;
$secondary: #444444;
$primary-hover: rgba(0, 182, 207, 0.80);
$secondary-hover: rgba(68, 68, 68, 0.8);
$body-color: $secondary;
$link-color: $secondary;
$link-decoration: underline;
$link-hover-color: lighten($link-color, 15%);
$white: #fff;

//
$component-active-bg: $white;
//$font-family-sans-serif: "Open Sans", "Helvetica Neue", Arial;
//$font-family-sans-serif:-apple-system, BlinkMacSystemFont, "Segoe UI", Open Sans, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//$headings-font-family: "Raleway";
$font-family-base: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1rem;
//
$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.125;
$h4-font-size: $font-size-base * 1;
//

$btn-font-size: 1rem;
$font-weight-bold: 700;
$btn-font-weight: $font-weight-bold;
$btn-padding-y: 8px;
$btn-padding-x: 16px;
//

$navbar-padding-y: 16px;
$navbar-nav-link-padding-x: 1.25rem;
$navbar-brand-height: 1.875rem;
$navbar-toggler-font-size: 1.5rem;
$navbar-light-color: $secondary;
$navbar-light-hover-color: lighten($link-color, 15%);
$navbar-light-active-color: $secondary;
$navbar-light-toggler-border-color: none;
$navbar-toggler-border-radius: 0;
$navbar-dark-toggler-border-color: none;


